<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="col-12 mt-2">
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <router-link to="/users/restaurant">
              <button class="btn btn-info rounded-pill mb-2">
                Back
              </button>
            </router-link>
            <h4 class="card-title mb-0">{{ headerTitle }}</h4>
            <br />
            <div
              v-if="alert || apiResponceError"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-2"
                label="Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="name"
                  type="text"
                  required
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Email *"
                type="email"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  @keyup="isEmailValid"
                  v-model="email"
                  type="text"
                  required
                  placeholder="Enter Email"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Phone Number *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="phone_number"
                  type="number"
                  required
                  placeholder="Enter Phone Number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Password *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  placeholder="Enter Password"
                ></b-form-input>
              </b-form-group>

              <div
                style="display:flex;justify-content:space-between;align-items:center;"
              >
                <div style="width:100%">
                  <b-form-group
                    id="input-group-2"
                    label="Business  Address:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="businessAddress"
                      type="text"
                      required
                      placeholder="Please type Business Address"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <!-- <div style="width:10%;padding-left:10px">
                  <a class="btn btn-info" @click="appendRow">Add</a>
                </div> -->
              </div>

              <br /><br />

              <b-button ref="save" type="submit" class="btn-info"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
      <br /><br />
      <template v-if="isShowModel">
        <VerficationModal
          :show="showModal"
          :userObj="userObj"
          :actionType="actionType"
          @deleteImageRecord="deleteImageRecord"
          @deleteDeliveryAddress="deleteDeliveryAddress"
          @closeModal="closeModal"
        />
      </template>
      <br /><br />

      <br />
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  components: {
    // Multiselect,
    VerficationModal,
    // VueGoogleAutocomplete,
  },
  data() {
    return {
      current_address: [],
      changeClasss: false,
      deliveryAddressArray: [],
      tempDeletedDeliveryAddress: [],
      businessAddress: "",
      deliveryAddresstempArray: "",
      headerTitle: "Add Restaurant User",
      phone_number: "",
      password: "",
      currentAddressname: "",
      email: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      profileimageUrl: null,
      profileImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      apiResponceError: false,
      price: "",
      show: true,
      apiUrl: "/user/register",
    };
  },

  mounted() {
    document.title = "Add Restaurant user";
    if (this.$router.history.current.path == "/editrestaurantuser") {
      document.title = "Edit Restaurant user";
      this.apiUrl = "/user/updateUser";
      this.showImagesViewTable = true;
      this.headerTitle = "Edit Restaurant User";
      this.editDataApppend();
    }
    for (let ref in this.$refs) {
      this.$refs[ref].focus();
    }
  },
  methods: {
    isEmailValid: function isEmailValid() {
      this.apiResponceError = false;
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
      }
      return responce;
    },
    editDataApppend() {
      this.editrestaurantuser = JSON.parse(
        localStorage.getItem("editrestaurantuser")
      );

      this.name = this.editrestaurantuser.username;
      this.id = this.editrestaurantuser.id;
      this.phone_number = this.editrestaurantuser.phone_number;
      this.email = this.editrestaurantuser.email;
      this.businessAddress =
        this.editrestaurantuser.address[0]["street"] +
        " " +
        this.editrestaurantuser.address[0]["city"] +
        " " +
        this.editrestaurantuser.address[0]["state"];
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        evt.preventDefault();

        const formData = new FormData();

        if (this.password.length > 0 && this.password.length < 6) {
          this.validationMessage("The Password must be at least 6 characters.");
          return false;
        } else {
          this.alert = false;
        }

        if (this.phone_number.length > 0 && this.phone_number.length < 11) {
          this.validationMessage(
            "The phone number must be at least 11 characters."
          );
          return false;
        } else {
          this.alert = false;
        }
        if (this.alert == true) {
          evt.preventDefault();
          return false;
        }
        evt.preventDefault();
        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("username", this.name);
        formData.append("email", this.email);
        formData.append("phone_number", this.phone_number);
        formData.append("password", this.password);
        formData.append("type", "restaurant");
        formData.append("platform", "adminpanel");
        formData.append(
          "tempDeletedDeliveryAddress",
          this.tempDeletedDeliveryAddress
        );
        formData.append("addresses", JSON.stringify(this.current_address));
        if (this.profileImage) {
          formData.append("profile_image", this.profileImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "restaurantuser" });
            }
            if (response.data.code == 422) {
              this.apiResponceError = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllCustomer() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
